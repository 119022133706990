@font-face {
  font-family: "Inter";
  src: local("Inter Regular"), local("Inter-Regular"),
    url("./assets/fonts/Inter-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter ExtraLight"), local("Inter-ExtraLight"),
    url("./assets/fonts/Inter-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Light"), local("Inter-Light"),
    url("./assets/fonts/Inter-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Medium"), local("Inter-Medium"),
    url("./assets/fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Bold"), local("Inter-Bold"),
    url("./assets/fonts/Inter-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter ExtraBold"), local("Inter-ExtraBold"),
    url("./assets/fonts/Inter-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Bold"), local("Inter-Bold"),
    url("./assets/fonts/Inter-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter SemiBold"), local("Inter-SemiBold"),
    url("./assets/fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  /* font-display: swap; */
}

@font-face {
  font-family: "Inter";
  src: local("Inter Thin"), local("Inter-Thin"),
    url("./assets/fonts/Inter-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
